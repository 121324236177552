import { whooshPrimitives } from "./primitives";

type WhooshSurfaceColorTokens = {
  "surface-primary": string;
  "surface-secondary": string;
  "surface-dividers": string;
  "surface-overlay": string;
  "surface-inverse": string;

  "surface-main-primary-subtlest": string;
  "surface-main-primary-subtle": string;
  "surface-main-primary-neutral": string;

  "surface-main-secondary-subtlest": string;
  "surface-main-secondary-subtle": string;
  "surface-main-secondary-neutral": string;

  "color-surface-accent-blue-subtlest": string;
  "color-surface-accent-blue-subtle": string;
  "color-surface-accent-purple-subtlest": string;
  "color-surface-accent-purple-subtle": string;
  "color-surface-accent-pink-subtlest": string;
  "color-surface-accent-pink-subtle": string;

  "surface-status-success-subtlest": string;
  "surface-status-success-subtle": string;
  "surface-status-warning-subtlest": string;
  "surface-status-warning-subtle": string;
  "surface-status-error-subtlest": string;
  "surface-status-error-subtle": string;
};

export const whooshSurfaceColors: WhooshSurfaceColorTokens = {
  "surface-primary": whooshPrimitives.grayscale[0],
  "surface-secondary": whooshPrimitives.grayscale[100],
  "surface-dividers": whooshPrimitives.grayscale[500],
  "surface-overlay": whooshPrimitives.grayscale[1000],
  "surface-inverse": whooshPrimitives.grayscale[1000],

  "surface-main-primary-subtlest": whooshPrimitives.main.primary[100],
  "surface-main-primary-subtle": whooshPrimitives.main.primary[300],
  "surface-main-primary-neutral": whooshPrimitives.main.primary[500],

  "surface-main-secondary-subtlest": whooshPrimitives.main.secondary[100],
  "surface-main-secondary-subtle": whooshPrimitives.main.secondary[300],
  "surface-main-secondary-neutral": whooshPrimitives.main.secondary[500],

  "color-surface-accent-blue-subtlest": whooshPrimitives.accent.blue[100],
  "color-surface-accent-blue-subtle": whooshPrimitives.accent.blue[300],
  "color-surface-accent-purple-subtlest": whooshPrimitives.accent.purple[100],
  "color-surface-accent-purple-subtle": whooshPrimitives.accent.purple[300],
  "color-surface-accent-pink-subtlest": whooshPrimitives.accent.pink[100],
  "color-surface-accent-pink-subtle": whooshPrimitives.accent.pink[300],

  "surface-status-success-subtlest": whooshPrimitives.status.success[100],
  "surface-status-success-subtle": whooshPrimitives.status.success[300],
  "surface-status-warning-subtlest": whooshPrimitives.status.warning[100],
  "surface-status-warning-subtle": whooshPrimitives.status.warning[300],
  "surface-status-error-subtlest": whooshPrimitives.status.error[100],
  "surface-status-error-subtle": whooshPrimitives.status.error[300],
};
