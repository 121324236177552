import { AnimatePresence } from "framer-motion";
import React, { ReactNode, useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { IconButton } from "@/design-system/controls/IconButton";
import { CloseIcon } from "@/design-system/icons/actions";
import { DesignSize, WhooshVariants } from "@/design-system/theme";
import { useOnEscPress } from "@/utilities/hooks";

import { UI } from "./ui";

export const ModalParts = {
  Header: UI.ModalHeader,
  Body: UI.ModalBody,
  Footer: UI.ModalFooter,
};

interface ModalProps {
  isOpen: boolean;
  style?: React.CSSProperties;
  size?: DesignSize | "auto";
  fullHeight?: boolean;
  emitClose?: () => void;
  "data-cy"?: string;
  hideCloseButton?: boolean;
  children?: ReactNode | ReactNode[];
  ignoreSelfClose?: boolean;
}
export const Modal: React.FC<ModalProps> = ({ children, ...props }) => {
  const { isOpen, emitClose, size, hideCloseButton, ignoreSelfClose } = props;
  const [state, setState] = useState({ _isOpen: isOpen });

  useEffect(() => {
    setState((state) => ({ ...state, _isOpen: isOpen }));
  }, [isOpen]);

  useEffect(() => {
    if (!state._isOpen && emitClose) {
      emitClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state._isOpen]);

  useOnEscPress(() => {
    if (!ignoreSelfClose) {
      setState({ ...state, _isOpen: false });
    }
  });

  /**
   * Make sure our portal target exists, otherwise jam one in
   */
  let targetEl = document.getElementById("modal-root");
  if (!targetEl) {
    targetEl = document.createElement("div");
    targetEl.id = "modal-root";
    document.body.appendChild(targetEl);
  }

  const closeSelf = () => {
    if (!ignoreSelfClose) {
      setState({ ...state, _isOpen: false });
    }
  };

  return targetEl ? (
    ReactDOM.createPortal(
      <AnimatePresence>
        {state._isOpen && (
          <>
            <UI.ModalBackgroundOverlay
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={closeSelf}
            />
            <UI.ModalWrapper
              {...props}
              data-cy={props["data-cy"]}
              size={size}
              style={props.style}
              initial={{
                opacity: 0,
                y: 30,
                x: props.size === DesignSize.XL ? "0" : "-50%",
              }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 30 }}
            >
              {!hideCloseButton && (
                <UI.CloseButtonWrapper>
                  <IconButton
                    data-cy="modal-close-button"
                    variant={WhooshVariants.FLAT}
                    onClick={closeSelf}
                    size={DesignSize.LG}
                  >
                    <CloseIcon />
                  </IconButton>
                </UI.CloseButtonWrapper>
              )}
              {children}
            </UI.ModalWrapper>
          </>
        )}
      </AnimatePresence>,
      targetEl
    )
  ) : (
    <></>
  );
};
