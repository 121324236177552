import React, { ReactNode, useEffect, useState } from "react";

import { IconButton } from "@/design-system/controls/IconButton";
import { CheckmarkIcon, CloseIcon } from "@/design-system/icons/actions";
import { WhooshVariants, DesignSize } from "@/design-system/theme";

import { useToast } from ".";
import { UI } from "./ui";

export interface iToast {
  id?: string;
  index?: number;
  content: ReactNode;
  /* Length in MS the toast should remain for */
  timerLength?: number;
  /**
   * If True, the toast will not fade out automatically
   */
  shouldRequireDismiss?: boolean;
  /**
   * Controls the styling and display
   */
  semanticStyle: "success" | "error" | "info";
  /**
   * A callback to fire when the toast is dismissed or removed.
   */
  onRemove?: () => void;
  /**
   * if false - toast can never be dismissed.
   */
  canDismiss?: boolean;
}

interface iToastState {
  timerState: "Running" | "Paused" | "Stopped";
  timerRemaining: number;
  nextTimerDelay: number;
  startTime: number;
  timerPercent: number;
}
const defaultProps: Required<iToast> = {
  id: "",
  index: -1,
  content: "",
  shouldRequireDismiss: false,
  timerLength: 5000,
  semanticStyle: "info",
  onRemove: () => {},
  canDismiss: true,
};
export const Toast = (props: iToast) => {
  const {
    id,
    index,
    content,
    timerLength,
    shouldRequireDismiss,
    semanticStyle,
    onRemove,
    canDismiss,
  }: Required<iToast> = {
    ...defaultProps,
    ...props,
  };
  const [state, setState] = useState<iToastState>({
    timerState: "Running",
    timerRemaining: timerLength,
    nextTimerDelay: timerLength,
    startTime: Date.now(),
    timerPercent: 0,
  });
  const { removeToast } = useToast();
  /**
   * Handles starting the timer when we need it to start, as well as
   * clearing it out if our state changes
   */
  useEffect(() => {
    if (state.timerState === "Running" && !shouldRequireDismiss) {
      const timer = setTimeout(() => {
        if (id) {
          onRemove();
          removeToast(id);
        }
      }, state.nextTimerDelay);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [
    id,
    removeToast,
    state.timerState,
    shouldRequireDismiss,
    state.nextTimerDelay,
    onRemove,
  ]);

  /**
   * Handles updating our timerRemaning so that we can tick down our display
   */
  useEffect(() => {
    const interval = setInterval(() => {
      if (state.timerState === "Running" && !shouldRequireDismiss) {
        const remaining = state.timerRemaining - (Date.now() - state.startTime);
        let percent = 100 - (remaining / timerLength) * 100;
        if (percent > 100) {
          percent = 100;
        }
        setState((state) => ({
          ...state,
          timerPercent: percent,
        }));
      }
    }, 100);
    return () => clearInterval(interval);
  }, [
    state.timerState,
    timerLength,
    state.startTime,
    shouldRequireDismiss,
    state.timerRemaining,
  ]);

  /**
   * Timer will pause, and calc remaining time.
   */
  const pauseTimer = () => {
    if (state.timerState === "Running") {
      const remaining = state.timerRemaining - (Date.now() - state.startTime);
      setState({
        ...state,
        timerState: "Paused",
        nextTimerDelay: remaining,
        timerRemaining: remaining,
      });
    }
  };
  /**
   * Timer will resume with time it has remaining, but only if Paused.
   * Will not resume from "stop"
   */
  const resumeTimer = () => {
    if (state.timerState === "Paused") {
      setState({ ...state, timerState: "Running", startTime: Date.now() });
    }
  };
  /**
   * Stop represents a permanent stop state. The toast will now need to be manually
   * dismissed
   */
  const stopTimer = () => {
    setState({
      ...state,
      timerState: "Stopped",
      timerPercent: 0,
    });
  };
  return (
    <UI.ToastItem
      data-cy={`toast-item-${semanticStyle}-${index}`}
      layout
      initial={{ opacity: 1, scale: 0.2, y: 100 }}
      animate={{ opacity: 1, scale: 1, y: 0 }}
      exit={{ opacity: 0, scale: 0.7, y: 10 }}
      onHoverStart={pauseTimer}
      onHoverEnd={resumeTimer}
      onClick={stopTimer}
    >
      <UI.ToastFadeTimerWrapper semanticStyle={semanticStyle}>
        {semanticStyle === "success" && <CheckmarkIcon />}
        {semanticStyle === "info" && <span>i</span>}
        {semanticStyle === "error" && <CloseIcon />}
        <UI.ToastFadeTimer
          semanticStyle={semanticStyle}
          animate={{ height: `${state.timerPercent}%` }}
        />
      </UI.ToastFadeTimerWrapper>
      <UI.ToastContentWrapper>{content}</UI.ToastContentWrapper>

      <UI.CloseToastButtonWrapper>
        {id && canDismiss && (
          <IconButton
            variant={WhooshVariants.FLAT}
            onClick={() => {
              onRemove();
              removeToast(id);
            }}
            size={DesignSize.SM}
          >
            <CloseIcon />
          </IconButton>
        )}
      </UI.CloseToastButtonWrapper>
    </UI.ToastItem>
  );
};
