import { useEffect, useState } from "react";

const previewRegex = /^deploy-preview-(\d+)--whoosh-staff-master.netlify.app$/;

export function getEnvironment() {
  const host = window.location.host;
  if (previewRegex.test(host)) {
    return {
      DEPLOY_ENV: "review",
      LAUNCH_DARKLY_CLIENT_SIDE_ID: "611427a38232090d6a29dad7",
      FULLSTORY_TOKEN: "16R7TJ",
      CRONOFY_APP_HOST: "app.cronofy.com",
      CRONOFY_CLIENT_ID: "GH8v5MDsni5zEPZ39Vn3yV9xwI6p1H5P",
    };
  }
  switch (host) {
    case "app.whoosh.io":
      return {
        DEPLOY_ENV: "prod",
        LAUNCH_DARKLY_CLIENT_SIDE_ID: "611423c70b5b603f161aeaac",
        FULLSTORY_TOKEN: "16R7TJ",
        CRONOFY_APP_HOST: "app.cronofy.com",
        CRONOFY_CLIENT_ID: "6yIwzziXnftiH1hz0rS8e8JiNPVwmibN",
      };
    case "replica.whoosh.io":
      return {
        DEPLOY_ENV: "replica",
        LAUNCH_DARKLY_CLIENT_SIDE_ID: "611427a38232090d6a29dad7",
        FULLSTORY_TOKEN: "16R7TJ",
        CRONOFY_APP_HOST: "app.cronofy.com",
        CRONOFY_CLIENT_ID: "JIKDMIZIIKJU2gUDuL4kIIsc9zDshRyx",
      };
    case "staging.whoosh.io":
      return {
        DEPLOY_ENV: "staging",
        LAUNCH_DARKLY_CLIENT_SIDE_ID: "611427a38232090d6a29dad7",
        FULLSTORY_TOKEN: "16R7TJ",
        CRONOFY_APP_HOST: "app.cronofy.com",
        CRONOFY_CLIENT_ID: "JIKDMIZIIKJU2gUDuL4kIIsc9zDshRyx",
      };
    case "qa.whoosh.io":
      return {
        DEPLOY_ENV: "qa",
        LAUNCH_DARKLY_CLIENT_SIDE_ID: "630fa14051ef5912161cf05d",
        FULLSTORY_TOKEN: "16R7TJ",
        CRONOFY_APP_HOST: "app.cronofy.com",
        CRONOFY_CLIENT_ID: "JIKDMIZIIKJU2gUDuL4kIIsc9zDshRyx",
      };
    case "demo.whoosh.io":
      return {
        DEPLOY_ENV: "demo",
        LAUNCH_DARKLY_CLIENT_SIDE_ID: "6114270844cb563f30b95a16",
        FULLSTORY_TOKEN: "16R7TJ",
        CRONOFY_APP_HOST: "app.cronofy.com",
        CRONOFY_CLIENT_ID: "JIKDMIZIIKJU2gUDuL4kIIsc9zDshRyx",
      };
    case "localhost:3000":
      return {
        DEPLOY_ENV: "local",
        LAUNCH_DARKLY_CLIENT_SIDE_ID: "6114272ab919584d0bd10bac",
        FULLSTORY_TOKEN: "16R7TJ",
        CRONOFY_APP_HOST: "app.cronofy.com",
        CRONOFY_CLIENT_ID: "GH8v5MDsni5zEPZ39Vn3yV9xwI6p1H5P",
      };
    default:
      return {
        DEPLOY_ENV: "dev",
        LAUNCH_DARKLY_CLIENT_SIDE_ID: "6114272ab919584d0bd10bac",
        FULLSTORY_TOKEN: "16R7TJ",
        CRONOFY_APP_HOST: "app.cronofy.com",
        CRONOFY_CLIENT_ID: "GH8v5MDsni5zEPZ39Vn3yV9xwI6p1H5P",
      };
  }
}

var apiHost: string;

export const getBEHost = async () => {
  if (!apiHost) {
    apiHost = await determineApiHost();
  }
  return apiHost;
};

export const getBEUrl = async () => {
  const beHost = await getBEHost();
  const protocol = beHost === "localhost:4000" ? "http" : "https";
  return `${protocol}://${apiHost}`;
};

export const useBEUrl = () => {
  const [beUrl, setBEUrl] = useState(null as String | null);

  useEffect(() => {
    if (!beUrl) {
      const loadBEUrl = async () => {
        const url = await getBEUrl();
        setBEUrl(url);
      };
      loadBEUrl();
    }
  }, [beUrl]);
  return beUrl;
};

export const getBESocketUrl = async () => {
  const beHost = await getBEHost();
  const protocol = beHost === "localhost:4000" ? "ws" : "wss";
  return `${protocol}://${apiHost}`;
};

export const getReviewApiHost = (feHost: string) => {
  if (previewRegex.test(feHost)) {
    const matches = feHost.match(previewRegex);
    const prNumber = matches![1];
    return `whoosh-pr-${prNumber}.gigalixirapp.com`;
  } else {
    return null;
  }
};

const determineApiHost = async () => {
  const host = window.location.host;
  const reviewApiHost = getReviewApiHost(host);

  if (host === "localhost:3000") {
    return "localhost:4000";
  } else if (reviewApiHost) {
    // no longer default back to dev, PR backend should exist or it should fail.
    return reviewApiHost;
  } else {
    return `api.${host}`;
  }
};
