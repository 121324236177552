import { useContext } from "react";

import { ToastContext } from "./ToastProvider";

/**
 * Allows you to add a toast from anywhere in the application.
 * Toasts accept any ReactNode so you can include links if needed.
 * By default toasts will automatically fade out - but they offer an array of
 * optional parameters in the iToast interface.
 */
export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastContext");
  }

  return {
    addToast: context.add,
    removeToast: context.remove,
  };
};
