import { t } from "@lingui/macro";
import React, { useCallback, useEffect } from "react";

import { useToast } from "@/design-system/overlay/Toast";
import { iToast } from "@/design-system/overlay/Toast/Toast";
import { useIsOnline } from "@/utilities/NetworkStatus";

export const OfflineToast = () => {
  const { addToast, removeToast } = useToast();

  const addOfflineToast = useCallback(() => {
    const offlineToast: iToast = {
      id: "offline-message",
      content: t`You are Currently Offline. Some features will be unavailable.`,
      canDismiss: false,
      shouldRequireDismiss: true,
      semanticStyle: "error",
    };

    addToast(offlineToast);
  }, [addToast]);

  const removeOfflineToast = useCallback(() => {
    removeToast("offline-message");
  }, [removeToast]);

  const isOnline = useIsOnline();
  useEffect(() => {
    if (isOnline) {
      removeOfflineToast();
    }
    // If we remain offline for 2 seconds, show the message
    const interval = setTimeout(() => {
      if (navigator && !navigator.onLine && !isOnline) {
        addOfflineToast();
      }
    }, 2000);
    return () => {
      clearTimeout(interval);
    };
  }, [addOfflineToast, isOnline, removeOfflineToast]);
  return <></>;
};
