import { motion } from "framer-motion";
import styled from "styled-components/macro";

import { DesignSize, WhooshVariants } from "@/design-system/theme";

import { IconButtonShape } from ".";

export interface iIconButtonStyleProps {
  size?: DesignSize;
  variant: WhooshVariants;
  shape?: IconButtonShape;
  disabled?: boolean;
  tooltip?: string;
  ignoreIconStyles?: boolean;
  viewOnly?: boolean;
}

export const Tip = styled.div`
  position: absolute;
  bottom: 42px;
  font-size: 10px;
  font-weight: normal;
  background-color: ${(props) => props.theme.colors.outdated.neutrals.black};
  color: ${(props) => props.theme.colors.grayscale[0]};
  border-radius: 8px;
  padding: 0.5rem;
  width: 150px;
  line-height: 1.5;
  letter-spacing: -2%;
  text-align: center;

  pointer-events: none;
  z-index: 100;
  display: none;
`;

export const IconButtonStyled = styled(motion.button)<iIconButtonStyleProps>`
  position: relative;
  cursor: ${(props) => (props.viewOnly ? "default" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) =>
    props.theme.variantStyles[props.variant].backgroundColor};
  color: ${(props) => props.theme.variantStyles[props.variant].color};

  :hover {
    ${Tip} {
      display: block;
    }
  }

  ${(props) => {
    if (props.variant === WhooshVariants.SECONDARY) {
      return `
        border: 2px solid ${
          props.theme.variantStyles[props.variant].borderColor
        };
      `;
    }
    if (
      props.variant === WhooshVariants.MEMBER ||
      props.variant === WhooshVariants.GUEST
    ) {
      return `
      `;
    }

    return ``;
  }}

  ${(props) => {
    if (props.disabled && !props.viewOnly) {
      return `
        cursor: not-allowed;
        background-color: ${props.theme.colors.outdated.neutrals[600]};
        ${props.tooltip ? "opacity: 1" : "opacity: 0.4"};
      `;
    }
    return ``;
  }}

  /**
    Handle size and if round or not
  */
  ${(props) => {
    switch (props.shape) {
      case IconButtonShape.SQUARE:
        return `border-radius: 5px;`;
      default:
        return `border-radius: 50%;`;
    }
  }}
  ${(props) => {
    switch (props.size) {
      case DesignSize.SM:
        return `
        width: 28px;
        height: 28px;
        font-size: 14px;
      `;
      case DesignSize.LG:
        return `
        width: 40px;
        height: 40px;
        font-size: 16px;
      `;
      default:
        return `
          width: 32px;
          height: 32px;
          font-size: 14px;
        `;
    }
  }}

  /**
    Handle internal icon styles
  */
  svg {
    pointer-events: none;
    max-width: 100%;
    max-height: 100%;

    ${(props) => {
      let returnVal = ``;
      if (!props.ignoreIconStyles) {
        returnVal = `
        path {
          fill: inherit;
          stroke: inherit;
        }

        stroke: ${
          props.theme.variantStyles[
            props.disabled ? WhooshVariants.DIM : props.variant
          ].color
        };
      fill: ${
        props.theme.variantStyles[
          props.disabled ? WhooshVariants.DIM : props.variant
        ].color
      };
        `;
      }
      return returnVal;
    }}

    ${(props) => {
      switch (props.size) {
        case DesignSize.SM:
          return `
        width: 12px;
        height: 12px;
      `;
        case DesignSize.LG:
          return `
        width: 20px;
        height: 20px;
      `;
        default:
          return `
        width: 20px;
        height: 20px;
        `;
      }
    }}
  }

  /* iOS is dumb and requires 0 padding to make circles right */
  padding: 0px;
`;
