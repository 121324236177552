import { motion } from "framer-motion";
import styled from "styled-components/macro";

import { zIndexMap } from "@/design-system/theme/styleConstants";

const ToastList = styled.div`
  position: fixed;
  left: 25px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: visible;
  z-index: ${zIndexMap.top};
`;

const ToastItem = styled(motion.div)`
  min-width: 100px;
  min-height: 30px;
  border-radius: 8px;
  background-color: white;
  padding: 0;
  margin-bottom: 10px;
  display: flex;
  position: relative;
  overflow: hidden;
  max-width: 500px;

  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
`;

const CloseToastButtonWrapper = styled.div`
  position: absolute;
  top: 13px;
  right: 13px;
  button {
    svg {
      width: 14px;
      height: 14px;
      fill: ${(props) => props.theme.colors.outdated.neutrals._70};
    }
  }
`;

const ToastFadeTimer = styled(motion.div)<iToastStyleProps>`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 32px;
  height: 0%;
  z-index: ${zIndexMap._10};
  background-color: ${(props) =>
    props.theme.colors.outdated.semantic[props.semanticStyle]._20};
`;

interface iToastStyleProps {
  semanticStyle: "success" | "error" | "info";
}
const ToastFadeTimerWrapper = styled.div<iToastStyleProps>`
  width: 32px;
  min-width: 32px;
  background-color: ${(props) =>
    props.theme.colors.outdated.semantic[props.semanticStyle].base};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.grayscale[0]};
  svg {
    position: relative;
    z-index: ${zIndexMap._20};
    stroke: ${(props) => props.theme.colors.grayscale[0]};
    fill: ${(props) => props.theme.colors.grayscale[0]};
  }
  span {
    position: relative;
    z-index: ${zIndexMap._20};
    font-size: 18px;
  }
`;

const ToastContentWrapper = styled.div`
  padding: 20px;
  padding-right: 60px;
`;

export const UI = {
  ToastList,
  ToastItem,
  CloseToastButtonWrapper,
  ToastFadeTimer,
  ToastFadeTimerWrapper,
  ToastContentWrapper,
};
