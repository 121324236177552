import { motion } from "framer-motion";
import styled from "styled-components/macro";

import { DesignSize } from "@/design-system/theme";
import { zIndexMap } from "@/design-system/theme/styleConstants";

const ModalFooter = styled(motion.div)`
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 20px;
  padding-top: 24px;
`;

const ModalWrapper = styled(motion.div)<{
  size?: DesignSize | "auto";
  padding?: string;
  fullHeight?: boolean;
}>`
  position: fixed;
  left: ${(props) => (props.size === DesignSize.XL ? "3vw" : "50%")};
  transform: ${(props) =>
    props.size === DesignSize.XL ? "none" : "translateX(-50%)"};
  top: ${(props) => (props.size === DesignSize.XL ? "3vh" : "5vh")};
  bottom: ${(props) => (props.fullHeight ? "3vh" : "auto")};
  max-height: 94vh;
  width: ${(props) =>
    props.size === DesignSize.SM
      ? "600px"
      : props.size === DesignSize.XL
      ? "94vw"
      : "auto"};
  min-width: ${(props) =>
    props.size === DesignSize.SM || props.size === "auto" ? "0px" : "658px"};
  background: ${(props) => props.theme.colors.grayscale[0]};
  display: flex;
  flex-direction: column;
  overflow: visible;
  z-index: ${zIndexMap.modal};
  border-radius: 8px;
  padding: ${(props) => (props.padding ? props.padding : "0 24px 24px 24px")};

  @media (max-width: 1024px) {
    width: ${(props) =>
      props.size === DesignSize.SM
        ? "550px"
        : props.size === "auto"
        ? "auto"
        : "80vw"};
    min-width: 0;
  }

  @media (max-width: 650px) {
    width: calc(100% - 16px);
    top: 8px;
  }
`;

const ModalHeader = styled(motion.div)`
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 20px;
  padding-top: 32px;
  padding-bottom: 24px;
  text-align: center;
  font-size: 16px;

  @media (max-width: 650px) {
    padding-left: 2rem;
  }
`;
const ModalBody = styled(motion.div)`
  flex-grow: 1;
  flex-shrink: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ModalBackgroundOverlay = styled(motion.div)`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%
  backdrop-filter: blur(2px);
  --webkit-backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.25);
  z-index: ${zIndexMap.modal};
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 21px;
  left: 15px;
  z-index: 1;
`;

export const UI = {
  ModalBackgroundOverlay,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalWrapper,
  CloseButtonWrapper,
};
