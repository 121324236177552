import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { I18nProvider } from "@lingui/react";
import * as Sentry from "@sentry/react";
import { ThemeProvider as ShopifyProvider } from "@shopify/restyle";
import { NativeBaseProvider } from "native-base";
import React, { Suspense, useState } from "react";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { DndProvider, useDrop } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { Router } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import { ThemeProvider } from "styled-components/macro";

import { Button } from "@/design-system/controls/Button";
import { BasicErrorScreen } from "@/design-system/errors/BasicErrorScreen";
import { AppRouter } from "@/routes";
import history from "@/routes/history";
import { useTodaysDate } from "@/utilities/date-utils";
import Loading from "@design-system/overlay/Loading";
import { ToastProvider } from "@design-system/overlay/Toast";
import { WhooshNBStaffTheme, whooshTheme } from "@design-system/theme";
import {
  GLOBALSTYLES,
  TOUCHGLOBALSTYLES,
} from "@design-system/theme/globalStyleProvider";
import { NORMALIZE } from "@design-system/theme/normalizeProvider";
import { theme as ShopifyTheme } from "@whoosh/design-system/src/theme/restyle/theme";

import { messages } from "./locales/en/messages.js";
import { GlobalConfirmationModal } from "./staffApp/components/GlobalConfirmationModal";

function App() {
  /**
   * Polyfills
   */
  smoothscroll.polyfill();
  /**
   * Check and see if we have a session in local storage. If so
   * load it up.
   */
  const lang = "en";
  i18n.load(lang, messages);
  i18n.activate(lang);

  /**
   * This will force the app to refresh if "today" ever rolls over to a new day
   * TODO: Feels like jank. Probably should remove it?
   */
  const today = useTodaysDate();
  const [curToday, setToday] = useState(today);
  useEffect(() => {
    if (today !== curToday) {
      setToday(curToday);
      window.location.replace(window.location.href);
    }
  }, [today, curToday]);

  const logoutButton = (
    <Button onClick={() => (window.location.href = "/app/logout")}>
      {t`Click here to logout`}
    </Button>
  );

  return (
    <>
      <ShopifyProvider theme={ShopifyTheme}>
        <I18nProvider i18n={i18n}>
          <NativeBaseProvider theme={WhooshNBStaffTheme}>
            <div style={{ height: "100vh" }}>
              <ThemeProvider theme={whooshTheme}>
                <Sentry.ErrorBoundary
                  beforeCapture={(scope, error) => {
                    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
                    if (
                      error?.message &&
                      chunkFailedMessage.test(error.message)
                    ) {
                      window.location.reload();
                    }
                  }}
                  fallback={({ resetError }) => (
                    <BasicErrorScreen
                      resetError={resetError}
                      logoutButton={logoutButton}
                    />
                  )}
                >
                  <DndProvider
                    backend={TouchBackend}
                    options={{
                      enableMouseEvents: true,
                      delayTouchStart: 300,
                      touchSlop: 20,
                    }}
                  >
                    <ToastProvider>
                      <GlobalConfirmationModal />
                      <Suspense fallback={<Loading />}>
                        <Router history={history}>
                          <NORMALIZE />
                          <GLOBALSTYLES />
                          <DndMobileStyleFix />
                          <AppRouter />
                        </Router>
                      </Suspense>
                    </ToastProvider>
                  </DndProvider>
                </Sentry.ErrorBoundary>
              </ThemeProvider>
            </div>
          </NativeBaseProvider>
        </I18nProvider>
      </ShopifyProvider>
    </>
  );
}

/**
 *
 * This exists because ios will aggressively highlight text on long press.
 * Since the current touch handlers start a drag on 'move', we need to
 * turn off the highlighting as soon as the drag starts, otherwise we get
 * silly highlighting on the entire page.
 *
 * Could probably fix this by futzing with the touch backend to start on long press
 * rather than move, but this is easier.
 */
const DndMobileStyleFix = () => {
  const [{ item }] = useDrop(
    () => ({
      accept: "any",
      collect: (monitor) => ({
        item: monitor.getItem(),
      }),
    }),
    []
  );
  return isMobile && !!item ? <TOUCHGLOBALSTYLES /> : null;
};

export default App;
