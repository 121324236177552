import { AnimatePresence, AnimateSharedLayout } from "framer-motion";
import React, { ReactNode } from "react";
import { v4 as uuidv4 } from "uuid";

import { iToast, Toast } from "./Toast";
import { UI } from "./ui";

interface iToastContext {
  add: (toast: iToast) => void;
  remove: (toadId: string) => void;
}

export const ToastContext = React.createContext<iToastContext | null>(null);
const { Provider } = ToastContext;

interface iToastProviderState {
  toasts: iToast[];
}

const initialToastState: iToastProviderState = {
  toasts: [],
};

type iToastProviderActionType = "AddToast" | "RemoveToast";

interface iToastProviderAction {
  type: iToastProviderActionType;
  payload: any;
}

const toastReducer = (
  state: iToastProviderState,
  action: iToastProviderAction
) => {
  switch (action.type) {
    case "AddToast":
      //Validate id doesn't already exist ?
      const newId = action.payload.toast.id
        ? action.payload.toast.id
        : uuidv4();
      const newToast = { ...action.payload.toast, id: newId };
      return { ...state, toasts: [...state.toasts, newToast] };
    case "RemoveToast": {
      const toasts = state.toasts.filter(
        (t) => t.id !== action.payload.toastId
      );
      return { ...state, toasts: toasts };
    }
    default:
      return state;
  }
};
export class ToastProvider extends React.Component<
  { children?: ReactNode | ReactNode[] },
  iToastProviderState
> {
  state = initialToastState;
  /**
   *
   * Functions to interact with the list of Toasts
   */
  add = (toast: iToast) => {
    const newState = toastReducer(this.state, {
      type: "AddToast",
      payload: { toast: toast },
    });
    this.setState(newState);
  };
  remove = (toastId: string) => {
    const newState = toastReducer(this.state, {
      type: "RemoveToast",
      payload: { toastId: toastId },
    });
    this.setState(newState);
  };

  render() {
    /**
     * Make sure our portal target exists, otherwise jam one in
     */
    let portalElement = document.getElementById("toast-root");
    if (!portalElement) {
      portalElement = document.createElement("div");
      portalElement.id = "toast-root";
      document.body.appendChild(portalElement);
    }
    const { add, remove } = this;

    return (
      <Provider value={{ add, remove }}>
        {this.props.children}
        {portalElement && (
          <UI.ToastList>
            <AnimateSharedLayout>
              <AnimatePresence>
                {this.state.toasts.map((t, i) => (
                  <Toast key={`toast-item-${t.id}`} index={i} {...t} />
                ))}
              </AnimatePresence>
            </AnimateSharedLayout>
          </UI.ToastList>
        )}
      </Provider>
    );
  }
}
