import {
  add,
  differenceInYears,
  format,
  isBefore,
  isSameDay,
  isValid,
  parse,
  parseISO,
  startOfMonth,
  sub,
  subMonths,
} from "date-fns";
import { convertToLocalTime } from "date-fns-timezone";
import { useEffect, useState } from "react";

export const getHoursMinutesFromHHMM = (timeString: string) => {
  const splitString = timeString.split(":");
  const hours = parseInt(splitString[0], 10);
  const minutes = parseInt(splitString[1], 10);
  return {
    hours,
    minutes,
  };
};
export const getTotalMinutesFromHHMM = (timeString: string | null) => {
  if (timeString === null) return null;
  const { hours, minutes } = getHoursMinutesFromHHMM(timeString);
  return hours * 60 + minutes;
};

export const parseTimeFromInput = (val: string) => {
  if (val.match(/^\d\d:\d\d$/)) return `${val}:00`;
  return val;
};

export const convertHoursMinutesToTimeString = (
  hours: number,
  minutes: number,
  seconds?: number
) => {
  const newHourString = hours.toString().length > 1 ? hours : `0${hours}`;
  const newMinuteString =
    minutes.toString().length > 1 ? minutes : `0${minutes}`;
  const newSecondsString = seconds
    ? seconds.toString().length > 1
      ? seconds
      : `0${seconds}`
    : "00";
  const newTime = `${newHourString}:${newMinuteString}:${newSecondsString}`;
  return newTime;
};

/**
 *
 * @param timeString string in HH:mm:ss format
 * @param meridian Do you want am/pm ?
 * @returns formatted string in "h:mm aaaa" format or "h:mm" format without
 */
export const formatHHMM = (
  timeString: string,
  meridian: boolean = true,
  amPmSpace = true
) => {
  if (!isValid(parse(timeString, "HH:mm:ss", new Date()))) {
    return null;
  }

  const date = parse(timeString, "HH:mm:ss", new Date());
  const dateString = meridian ? `h:mm${amPmSpace ? " " : ""}aaa` : "h:mm";
  return format(date, dateString);
};

export const convertTo24HourString = (timeString: string) => {
  if (!isValid(parse(timeString, "H:MM", new Date()))) {
    return null;
  }

  const date = parse(timeString, "HH:mm:ss", new Date());
  return format(date, "H-MM");
};

/**
 *
 * Validates that a date string is both in our preferred 'yyyy-MM-dd' format
 * and is also parsable as a real date
 */
export const isDateStringValid = (dateString: string | null | undefined) => {
  return !!dateString && isValid(parse(dateString, "yyyy-MM-dd", new Date()));
};

/**
 *
 * Validates that a datetime string is both in our preferred iso format
 * and is also parsable as a real date
 */
export const isDateTimeStringValid = (
  dateString: string | null | undefined
) => {
  return !!dateString && isValid(parseISO(dateString));
};

export const convertServerTimeToLocal = (date: string) => {
  return convertToLocalTime(date, { timeZone: "UTC" });
};

/**
 * Hook that handles automatically updating components when the date changes.
 * Will pass new value when date changes.
 * @returns curDate: 'yyyy-MM-dd'
 */
export const useTodaysDate = () => {
  const date = format(new Date(), "yyyy-MM-dd");
  const [state, setState] = useState({ curDate: date });
  useEffect(() => {
    const interval = setInterval(() => {
      const curDate = format(new Date(), "yyyy-MM-dd");
      if (curDate !== state.curDate) {
        setState({ ...state, curDate: curDate });
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [state]);

  return state.curDate;
};
/**
 * Hook that will ingest a datestring and return whether that date string
 * is today.
 * @param dateString "yyyy-MM-dd"
 * @returns boolean;
 */
export const useIsToday = (dateString: string) => {
  const today = useTodaysDate();
  const parsedToday = parse(today, "yyyy-MM-dd", new Date());
  const date = parse(dateString, "yyyy-MM-dd", new Date());
  return isSameDay(parsedToday, date);
};

//TODO: Need to rename this to "isPastDate" as it's not a hook.
/**
 *
 * @param dateString "yyyy-MM-dd"
 * @returns whether the date is before today.
 */
export const useIsPastDate = (dateString: string) => {
  const today = useTodaysDate();
  const parsedToday = parse(today, "yyyy-MM-dd", new Date());
  const dateComp = parse(dateString, "yyyy-MM-dd", new Date());
  return isBefore(dateComp, parsedToday);
};

/**
 * gets the number of years from given date.
 */
export const yearsSinceDate = (dateString: string) => {
  const date = parse(dateString, "yyyy-MM-dd", new Date());
  return differenceInYears(new Date(), date);
};

/**
 * gets the year from a given date
 */
export const getYearFromDate = (dateString: string) => {
  return parse(dateString, "yyyy-MM-dd", new Date()).getFullYear();
};

/**
 *
 * @param dateString yyyy-MM-dd format
 * @returns Date object after parsing string.
 */
export const convertDateStringToObj = (
  dateString: string | null | undefined
) => {
  if (!dateString || !isDateStringValid(dateString)) {
    throw new Error(
      `convertDateStringToObj must be called with ` +
        `string in yyyy-MM-dd format. Was instead called with ${dateString}`
    );
  }

  return parse(dateString, "yyyy-MM-dd", new Date());
};

/**
 *
 * @param date
 * @returns string in yyyy-MM-dd format.
 */
export const convertDateObjToServerStringFormat = (date: Date) => {
  return format(date, "yyyy-MM-dd");
};

export const convertServerTimeString = (
  serverTimeString: string | null | undefined,
  formatCode: string
) => {
  let displayDate = null;
  if (serverTimeString) {
    const dateObj = convertServerTimeToLocal(serverTimeString);
    displayDate = format(dateObj, formatCode);
    return displayDate;
  } else return null;
};

export const getBeginningOfYear = () => {
  const date = new Date(new Date().getFullYear(), 0, 1);
  return format(date, "yyyy-MM-dd");
};

export const getSixMonthsAgo = () => {
  const sixMonthsAgo = subMonths(new Date(), 6);
  return format(sixMonthsAgo, "yyyy-MM-dd");
};

export const getBeginningOfMonth = () => {
  const beginningOfMonth = startOfMonth(new Date());
  return format(beginningOfMonth, "yyyy-MM-dd");
};

export const addMinutesToTimeString = (timeString: string, minutes: number) => {
  const date = parse(timeString, "HH:mm:ss", new Date());
  const time = format(date, "HH:mm:ss");
  if (time) {
    let dateObj = parse(time, "HH:mm:ss", new Date());
    if (minutes > 0) {
      dateObj = add(dateObj, {
        minutes,
      });
    }

    if (minutes < 0) {
      dateObj = sub(dateObj, {
        minutes: Math.abs(minutes),
      });
    }

    return format(dateObj, "HH:mm:ss");
  }

  return timeString;
};
