const disabled = {
  background: "button-disabled-surface-neutral",
  _icon: {
    color: "button-disabled-text",
  },
  _text: {
    color: "button-disabled-icon",
  },
};

export const ButtonStyles = {
  baseStyle: () => {
    return {
      rounded: "8px",
      alignItems: "center",
    };
  },
  defaultProps: {
    variant: "main",
    size: "md",
    colorScheme: null,
    _text: {
      variant: "mainBaseSemiBold",
    },
  },
  variants: {
    main: () => ({
      bg: "button-main-surface-neutral",
      _text: {
        color: "button-main-text",
      },
      _icon: {
        color: "button-main-icon",
      },
      _hover: {
        background: "button-main-surface-hover",
      },
      _pressed: {
        background: "button-main-surface-pressed",
      },
      _disabled: { ...disabled },
    }),
    alt: () => ({
      bg: "button-alt-surface-neutral",
      _text: {
        color: "button-alt-text",
      },
      _icon: {
        color: "button-alt-icon",
      },
      _hover: {
        background: "button-alt-surface-hover",
      },
      _pressed: {
        background: "button-alt-surface-pressed",
      },
      _disabled: { ...disabled },
    }),
    white: () => ({
      bg: "button-white-surface-neutral",
      _text: {
        color: "button-white-text",
      },
      _icon: {
        color: "button-white-icon",
      },
      _hover: {
        background: "button-white-surface-hover",
      },
      _pressed: {
        background: "button-white-surface-pressed",
      },
      _disabled: { ...disabled },
    }),
    danger: () => ({
      bg: "button-danger-surface-neutral",
      _text: {
        color: "button-danger-text",
      },
      _icon: {
        color: "button-danger-icon",
      },
      _hover: {
        background: "button-danger-surface-hover",
      },
      _pressed: {
        background: "button-danger-surface-pressed",
      },
      _disabled: { ...disabled },
    }),
    ghost: ({ color }: any) => ({
      bg: "button-ghost-surface-neutral",
      _text: {
        color: color ? `${color}` : "button-ghost-text",
      },
      _icon: {
        color: color ? `${color}` : "button-ghost-icon",
      },
      _hover: {
        background: "button-ghost-surface-hover",
        _text: {
          opacity: 0.7,
        },
        _icon: {
          opacity: 0.7,
        },
      },
      _pressed: {
        background: "button-ghost-surface-pressed",
        _text: {
          opacity: 0.5,
        },
        _icon: {
          opacity: 0.5,
        },
      },
      _disabled: { ...disabled },
    }),
  },
  sizes: {
    md: {
      height: "56px",
      width: "56px",
      px: 5,
      _stack: {
        space: 4,
      },
      _icon: {
        size: "sm",
      },
      _text: {
        fontSize: "14px",
        variant: "mainBaseSemiBold",
      },
    },
    sm: {
      height: "48px",
      width: "48px",
      _stack: {
        space: 3,
      },
      px: 4,
      _text: {
        fontSize: "14px",
        variant: "mainBaseSemiBold",
      },
      _icon: {
        size: "sm",
      },
    },
    xs: {
      height: "32px",
      width: "32px",
      _stack: {
        space: 2,
      },
      px: 2,
      _text: {
        fontSize: "11px",
        variant: "mainMicro",
      },
      _icon: {
        size: "sm",
      },
    },
  },
};
