import { Trans } from "@lingui/macro";
import { useEffect, useState } from "react";

import { useToast } from "@/design-system/overlay/Toast";
import { iToast } from "@/design-system/overlay/Toast/Toast";
import {
  PubSubGlobalSubjects,
  PubSubTopics,
  pubSubService,
} from "@/utilities/PubSubService";

export const useServiceWorkerUpdateToast = () => {
  const [hasToast, setHasToast] = useState(false);
  const { addToast } = useToast();
  useEffect(() => {
    const subscription = pubSubService.onMessage().subscribe((message) => {
      if (
        message.topic === PubSubTopics.GLOBAL &&
        message.subject === PubSubGlobalSubjects.ServiceWorkerUpdate
      ) {
        if (hasToast) return;
        const registration: ServiceWorkerRegistration = message.payload;

        if (registration.waiting) {
          registration.waiting.addEventListener("statechange", (event: any) => {
            if (event.target?.state === "activated") {
              window.location.reload();
            }
          });

          //If we have updates - just apply them immediately. Timeout is necessary to ensure
          //above listener binds, and allow toast to show.
          setTimeout(() => {
            registration?.waiting?.postMessage({ type: "SKIP_WAITING" });
          }, 500);
        }

        // If our service worker updated throw up a toast.
        // If the user dismisses the toast, throw another up in 5 minutes
        const toastBase: iToast = {
          content: (
            <div>
              <Trans>
                Hot Dog! We've got some updates to apply. Just a sec.
              </Trans>
            </div>
          ),
          shouldRequireDismiss: true,
          semanticStyle: "info",
        };
        const toast = { ...toastBase };
        toast.onRemove = () => {
          setTimeout(() => {
            const newToast = { ...toastBase };
            newToast.canDismiss = false;
          }, 60 * 1000 * 5);
        };
        addToast(toast);
        setHasToast(true);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [addToast, hasToast]);
};
