import { createTheme } from "@shopify/restyle";
import { ResponsiveValue } from "@shopify/restyle";
import { Platform } from "react-native";

import {
  whooshButtonColors,
  whooshIconColors,
  whooshPrimitiveTokens,
  whooshSurfaceColors,
  whooshTextColors,
} from "../colors";
import { whooshOutlineColors } from "../colors/outline";
import { whooshUserSelectableColors } from "../colors/userSelectable";

export type WhooshThemeColors = ResponsiveValue<
  keyof Theme["colors"],
  Theme["breakpoints"]
>;

export const theme = createTheme({
  colors: {
    ...whooshPrimitiveTokens,
    ...whooshTextColors,
    ...whooshSurfaceColors,
    ...whooshIconColors,
    ...whooshButtonColors,
    ...whooshOutlineColors,
    ...whooshUserSelectableColors,
  },
  breakpoints: {
    base: 0,
    xs: 368,
    sm: 480,
    md: 768,
    agendaSplit: 834,
    eventCardColumn: 990,
    lg: 1100,
    xl: 1280,
  },
  borderRadii: {
    0: 0,
    xxxs: 2,
    xxs: 4,
    xs: 6,
    sm: 8,
    smd: 12,
    md: 16,
    lg: 24,
  },
  zIndices: {
    back: 0,
    10: 10,
    100: 100,
    200: 200,
    300: 300,
    400: 400,
    500: 500,
    600: 600,
    700: 700,
    800: 800,
    900: 900,
    1000: 1000,
  },
  spacing: {
    /**
     * These older ones are defunct and shoudl not be used. These were added
     * before Jakub creating his spacing steps. We will slowly choke them out.
     */
    "-10": -10,
    "-6": -24,
    "-1": -1,
    ".5": 2,
    0: 0,
    1: 4,
    "1.5": 6,
    2: 8,
    "2.5": 10,
    3: 12,
    4: 16,
    5: 20,
    6: 24,
    7: 28,
    8: 32,
    9: 36,
    10: 40,
    11: 44,
    12: 48,
    13: 52,
    14: 56,
    15: 60,
    16: 64,
    17: 68,
    18: 72,
    19: 76,
    20: 80,
    21: 84,
    22: 88,
    23: 92,
    24: 96,
    25: 100,
    32: 128,
    34: 136,
    /**
     * These are the ones that match Jakub's designs. We will be using these tokens going forward.
     */
    "000": 0,
    "050": 4,
    "100": 8,
    "150": 12,
    "200": 16,
    "250": 20,
    "300": 24,
    "400": 32,
    "500": 40,
    "600": 48,
    "800": 64,
    "1000": 80,
  },
  textVariants: {
    mainTitle2: {
      fontFamily:
        Platform.OS === "web" ? "Inter_500Medium, Inter" : "Inter_500Medium",
      fontSize: 28,
      lineHeight: 32,
      letterSpacing: 28 * -0.02,
    },
    mainHeading: {
      fontFamily:
        Platform.OS === "web" ? "Inter_500Medium, Inter" : "Inter_500Medium",
      fontSize: 18,
      fontWeight: 500,
      lineHeight: 24,
      letterSpacing: 18 * -0.03,
    },
    mainBaseMedium: {
      fontFamily:
        Platform.OS === "web" ? "Inter_500Medium, Inter" : "Inter_500Medium",
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 18,
      letterSpacing: 14 * -0.02,
    },
    mainBaseSemiBold: {
      fontSize: 14,
      fontWeight: 500,
      fontFamily:
        Platform.OS === "web"
          ? "Inter_600SemiBold, Inter"
          : "Inter_600SemiBold",
      lineHeight: 18,
      letterSpacing: 14 * -0.02,
    },
    mainSmall: {
      fontSize: 13,
      fontWeight: 500,
      fontFamily:
        Platform.OS === "web"
          ? "Inter_600SemiBold, Inter"
          : "Inter_600SemiBold",
      lineHeight: 16,
      letterSpacing: 13 * -0.02,
    },
    mainTiny: {
      fontSize: 11,
      fontWeight: 500,
      fontFamily:
        Platform.OS === "web"
          ? "Inter_600SemiBold, Inter"
          : "Inter_600SemiBold",
      lineHeight: 16,
      letterSpacing: 11 * -0.02,
    },
    mainMicro: {
      fontSize: 10,
      fontWeight: 500,
      fontFamily:
        Platform.OS === "web"
          ? "Inter_600SemiBold, Inter"
          : "Inter_600SemiBold",
      lineHeight: 12,
      letterSpacing: 0,
    },
    serifTitle1: {
      fontSize: 40,
      lineHeight: 44,
      letterSpacing: 40 * -0.04,
      fontFamily:
        Platform.OS === "web" ? "Lora_500Medium, Lora" : "Lora_500Medium",
    },
    serifTitle2: {
      fontSize: 32,
      lineHeight: 36,
      letterSpacing: 32 * -0.04,
      fontFamily:
        Platform.OS === "web" ? "Lora_500Medium, Lora" : "Lora_500Medium",
    },
    serifTitle3: {
      fontSize: 24,
      lineHeight: 28,
      letterSpacing: 24 * -0.04,
      fontFamily:
        Platform.OS === "web" ? "Lora_500Medium, Lora" : "Lora_500Medium",
    },
    serifHeading: {
      fontSize: 20,
      lineHeight: 24,
      letterSpacing: 20 * -0.02,
      fontFamily:
        Platform.OS === "web" ? "Lora_500Medium, Lora" : "Lora_500Medium",
    },
    serifBase: {
      fontSize: 16,
      lineHeight: 16,
      letterSpacing: 16 * -0.02,
      fontFamily:
        Platform.OS === "web" ? "Lora_500Medium, Lora" : "Lora_500Medium",
    },
    defaults: {
      color: "text-primary",
      fontFamily:
        Platform.OS === "web" ? "Inter_500Medium, Inter" : "Inter_500Medium",
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 18,
      letterSpacing: 14 * -0.02,
    },
  },
});

export type Theme = typeof theme;
